/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Tabs,
  Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import { useAdContent } from '../../api/adTech';
import useResizeObserver from '../../hooks/utilHooks/useResizeObserverV2';

import { styles } from './PlatformIntelligencePage.styles';

const PlatformIntelligencePage = ({ classes }) => {
  const { data: videoList } = useAdContent();

  let content = null;

  const [width, setWidth] = useState(window.innerWidth);
  const ref = useResizeObserver((bounds) => setWidth(bounds.width));

  content = (
    <Tabs
      value={false}
      variant="scrollable"
      scrollButtons="auto"
      classes={{
        scrollButtons: classes.scrollButton,
        flexContainer: classes.flexContainer
      }}
      style={{ width: width }}
    >
      {videoList?.map((video, index) => (
        <div className={classes.cardWrapper} key={`${index}-${video.id}`}>
          <Card className={classes.card}>
            <Link
              className={classes.link}
              to={{
                pathname: `/admin/ml/video/${video.id}`
              }}
            >
              <CardActionArea
                classes={{
                  focusHighlight: classes.cardFocusHighlight
                }}
              >
                <CardMedia
                  className={classes.image}
                  title={video.title}
                  image={video.thumbnail}
                />
                <CardContent className={classes.content}>
                  <Typography className={classes.title}>
                    {video.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Link>
          </Card>
        </div>
      ))}
    </Tabs>
  );

  return (
    <>
      <div className={classes.root}>
        <div ref={ref} />
        <Typography className={classes.divider} color="secondary">
          VIDEOS
        </Typography>
        {content}
      </div>
    </>
  );
};

export default withStyles(styles)(PlatformIntelligencePage);
