import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import Loader from '../../../components/Loader';
import ErrorMessage from '../../../components/ErrorMessage';
import { useVideo } from '../../../api/video';
import VideoContainer from '../../../components/video/VideoContainer';
import { useAdList, AdItem } from '../../../api/adTech';

import { useStyles } from './GenAiVideoAdPage.styles';

type AppliedAdData = {
  applied: boolean;
  id: string;
  index: number;
  position: string;
  text: string;
  uploadedVideoAdId: string;
};

const convertToVideoTime = (value: string) =>
  new Date(+value * 1000).toISOString().substr(11, 8);

const getAdDataById = (adList: AdItem[], id: string) =>
  adList.find((item) => item.id === id);

const GenAiVideoAdPage = () => {
  const params = useParams<{ id: string }>();
  const id = params.id;
  const location = useLocation<{ appliedAdData: AppliedAdData[] }>();

  const appliedAdData = location.state?.appliedAdData;
  const adPositions = useMemo(
    () => appliedAdData?.map((ad) => Math.floor(parseFloat(ad.position))),
    [appliedAdData]
  );

  const { data: adList } = useAdList();
  const videoAdData = !adList
    ? null
    : getAdDataById(adList, appliedAdData?.[0].uploadedVideoAdId);

  const videoRef = useRef<HTMLVideoElement>(null);
  const videoAdRef = useRef<HTMLVideoElement>(null);
  const [isAdVisible, setIsAdVisible] = useState(false);
  const isAdSeen = useRef(false);
  useEffect(() => {
    const videoElem = videoRef.current;
    const videoAdElem = videoAdRef.current;

    const timeUpdateHandler = () => {
      const currentTime = videoElem?.currentTime ?? 0;
      if (
        adPositions.some((position) => Math.floor(currentTime) === position)
      ) {
        setIsAdVisible(true);
        videoElem?.pause();
        videoAdElem?.play();
      }
    };

    const onAdEnd = () => {
      setIsAdVisible(false);
      isAdSeen.current = true;
      if (videoElem) {
        videoElem.currentTime += 1;
      }
      videoElem?.play();
    };

    videoElem?.addEventListener('timeupdate', timeUpdateHandler);
    videoAdElem?.addEventListener('ended', onAdEnd);

    return () => {
      videoElem?.removeEventListener('timeupdate', timeUpdateHandler);
      videoAdElem?.removeEventListener('ended', onAdEnd);
    };
  }, [adPositions]);

  const { data: video, isLoading, isError, error } = useVideo(id);

  const styles = useStyles();

  let content = null;

  if (isLoading) {
    return (content = (
      <Loader loaderProps={{ size: 40 }} fullHeightCentralized />
    ));
  } else if (video && videoAdData) {
    content = (
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          className={styles.videoWrapResponse}
          style={{ position: 'relative' }}
        >
          <VideoContainer videoObject={video} ref={videoRef} />
          <div
            style={{
              position: 'absolute',
              zIndex: 2,
              top: '8px',
              width: '100%'
            }}
          >
            <VideoContainer
              videoObject={videoAdData}
              hideControls
              ref={videoAdRef}
              style={{ display: isAdVisible ? 'block' : 'none' }}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div>
            {appliedAdData?.map((adData, index) => (
              <li key={index}>
                {convertToVideoTime(adData.position)} - {adData.text}
              </li>
            ))}
          </div>
        </Grid>
      </Grid>
    );
  }

  if (isError) {
    content = <ErrorMessage error={String(error)} fullHeightCentralized />;
  }

  return (
    <div className={styles.root}>
      <div>{content}</div>
    </div>
  );
};

export default GenAiVideoAdPage;
