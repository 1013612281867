/* eslint-disable react/prop-types */
import React, { useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import {
  Typography,
  Grid,
  Slider as MaterialSlider,
  Button,
  Snackbar, Dialog, DialogTitle, DialogContent, Card, CardActionArea, CardContent, CardMedia
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import Loader from '../../components/Loader';
import ErrorMessage from '../../components/ErrorMessage';
import VideoContainer from '../../components/video/VideoContainer';
import VideoLayout from '../../components/video/VideoLayout';
import { API_SEARCH } from '../../api/search/searchUrls';
import { capitalizeFirstChar } from '../../shared';
import { useVideo } from '../../api/video';
import Storyboard from '../../components/Storyboard';
import VideoPageBannerSection from '../../components/VideoPageBannerSection';
import { useAdList } from '../../api/adTech/adTechQuery';

import { useStyles } from './GenAiVideoPage.styles';

const fetchSearch = async (query) => {
  const url = API_SEARCH(query);

  return fetch(url)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => console.error(error));
};

const getTagsByVideoTitle = (data, title) => {
  for (let item of data) {
    if (item.title === title) {
      return item.tags;
    }
  }
  return null;
};

const getVideoAdIdByVideoTitle = (data, title) => {
  for (let item of data) {
    if (item.title === title) {
      return item.id;
    }
  }
  return null;
};

const convertToVideoTime = (value) => {
  return new Date(value * 1000).toISOString().substr(11, 8);
};

const convertSearchResultsToMarks = (searchResults, videoDuration) => {
  const result = [{ value: 0, label: '00:00:00', position: '0' }];
  // FIXME:
  /*
   * better:
   * result.concat(searchResults.map((item, index) => {
   *  return {
   *   value: index + 1,
   *   label: convertToVideoTime(item.position),
   *   position: item.position
   * }
   * }))
   *
   * or:
   * searchResults.forEach((item, index) => {
   *   result.push({...})
   * })
   *
   * don't use .map() for side effects (pushing to an array). .map() is for transforming an array and getting new one
   */
  // eslint-disable-next-line array-callback-return
  searchResults.map((item, index) => {
    result.push({
      value: index + 1,
      label: convertToVideoTime(item.position),
      position: item.position
    });
  });
  result.push({
    value: searchResults.length + 1,
    label: convertToVideoTime(videoDuration),
    position: videoDuration.toString()
  });

  return result;
};

const filterSearchResultsByIdAndSize = (SearchResults, id, size) => {
  return SearchResults.filter((item) => item.id === id).slice(0, size);
};

const createThumbnails = (data) => {
  return data.map((item, index) => {
    return {
      id: index + 1,
      thumbnail: `https://accelerator.media${item.thumbnail}`,
      description: item.text
    };
  });
};

const GenAiVideoPage = () => {
  const [displaySlider, setDisplaySlider] = useState(false);
  const [uploadedVideoAdId, setUploadedVideoAdId] = useState(null);
  const [filteredSearchResults, setFilteredSearchResults] = useState(null);
  const [sliderValue, setSliderValue] = useState(1);
  const [marks, setMarks] = useState([]);
  const [appliedAdData, setAppliedAdData] = useState([]);
  const [alert, setAlert] = useState(false);
  const [displayNotification, setDisplayNotification] = useState(false);
  const [thumbnails, setThumbnails] = useState([]);
  const [tags, setTags] = useState(null);
  const [filename, setFilename] = useState(null);
  const [openSelectDialog, setOpenSelectDialog] = useState(false);

  const styles = useStyles();

  const params = useParams();
  const id = params.id;

  const { data: video, isLoading, isError, error } = useVideo(id);
  const { data: adList } = useAdList();

  const handleFileUploaded = (file) => {
    setSliderValue(1);
    setAlert(false);

    const tags = getTagsByVideoTitle(adList, file);

    const videoAdId = getVideoAdIdByVideoTitle(adList, file);

    fetchSearch(tags).then((searchResults) => {
      const filtered = filterSearchResultsByIdAndSize(searchResults, id, 3);
      if (filtered.length === 0) {
        console.warn('No search results for this video');
        setAlert(true);
        return;
      }
      const marks = convertSearchResultsToMarks(filtered, video.duration);
      setFilteredSearchResults(filtered);
      setMarks(marks);
      setTags(tags);
      setFilename(file);
      setDisplaySlider(true);
      setThumbnails(createThumbnails(filtered));
      setUploadedVideoAdId(videoAdId);
    });
  };

  const handleSliderChange = (e, newValue) => {
    if (newValue === 0 || newValue === marks.length - 1) return;
    videoRef.current.currentTime = marks[newValue].position;
    setSliderValue(newValue);
  };

  const handleApplyClick = (e) => {
    const buttonValue = Number(e.currentTarget.value);
    const adData = {
      index: buttonValue,
      id: filteredSearchResults[buttonValue].id,
      uploadedVideoAdId: uploadedVideoAdId,
      position: filteredSearchResults[buttonValue].position,
      text: filteredSearchResults[buttonValue].text,
      applied: true
    };
    setAppliedAdData((prev) => [...prev, adData]);
  };

  const handleDeleteClick = (e) => {
    const buttonValue = Number(e.currentTarget.value);
    const updatedAppliedAdData = appliedAdData.filter(
      (item) => item.index !== buttonValue
    );
    setAppliedAdData(updatedAppliedAdData);
  };

  const handleClickOpenSelectDialog = () => {
    setOpenSelectDialog(true);
  }

  const handleClickCloseSelectDialog = () => {
    setOpenSelectDialog(false);
  }

  const handleClickCard = (title)  => {
    setOpenSelectDialog(false);
    handleFileUploaded(title);
  }

  function valueLabel(value) {
    const filteredSearchResultsIndex = value - 1;
    const isApplied = appliedAdData.find(
      (item) => item.index === filteredSearchResultsIndex
    );
    return (
      <Button
        value={filteredSearchResultsIndex}
        onClick={isApplied ? handleDeleteClick : handleApplyClick}
        variant="contained"
        size="small"
      >
        {isApplied ? 'Delete' : 'Apply'}
      </Button>
    );
  }

  const videoRef = useRef();

  if (isLoading)
    return <Loader loaderProps={{ size: 80 }} fullHeightCentralized />;
  if (isError) <ErrorMessage error={String(error)} fullHeightCentralized />;

  return (
    <div className={styles.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={displayNotification}
        autoHideDuration={5000}
        onClose={() => setDisplayNotification(false)}
        message="Video successfully uploaded"
      />
      {video && (
        <VideoPageBannerSection
          poster={video.poster}
          title={video.title}
          description={video.description}
          tagCloud={video.tagCloud}
        />
      )}
      <VideoLayout>
        <div>
          <VideoContainer videoObject={video} ref={videoRef} />
        </div>
        <Storyboard videoElem={videoRef.current} />
      </VideoLayout>
      {alert && (
        <div className={styles.alert}>
          <Alert severity="info">No search results for this video</Alert>
        </div>
      )}
      <Grid container className={styles.underVideo}>
        <Grid item xs={12}>
          {displaySlider && (
            <MaterialSlider
              marks={marks}
              defaultValue={0}
              value={sliderValue}
              step={null}
              min={0}
              max={marks.length - 1}
              color="secondary"
              valueLabelDisplay="on"
              onChange={handleSliderChange}
              valueLabelFormat={valueLabel}
              classes={{
                markLabel: styles.sliderMarkLabel,
                mark: styles.sliderMark
              }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {displaySlider && (
            <div className={styles.thumbsContainer}>
              <Slider
                type="range"
                infinite={false}
                slidesToShow={thumbnails.length + 1}
                className={styles.slick}
              >
                {thumbnails.map(({ id, thumbnail: url, description }, idx) => (
                  <div key={id}>
                    <div className={styles.thumbWrapper}>
                      <button
                        onClick={() => handleSliderChange(null, idx + 1)}
                        style={{
                          backgroundImage: `url('${url}')`,
                          transform:
                            idx + 1 === sliderValue ? 'scale(1.2)' : 'none',
                          border: appliedAdData.some(
                            (item) => item.index === idx
                          )
                            ? '2px solid #f37037'
                            : '2px solid rgba(255, 255, 255, 0.3)'
                        }}
                        className={styles.thumb}
                        title={capitalizeFirstChar(description.trim())}
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <div>
            <div>
              {appliedAdData.map((adData, index) => (
                <li key={index}>
                  {convertToVideoTime(adData.position)} - {adData.text}
                </li>
              ))}
            </div>
            <div className={styles.buttonBlock}>
              {displaySlider && appliedAdData.length > 0 && (
                <Link
                  to={{
                    pathname: `/admin/ml/video/ad/${id}`,
                    state: { appliedAdData }
                  }}
                >
                  <Button variant="contained" color="primary">
                    Convert
                  </Button>
                </Link>
              )}
            </div>
          </div>
          <div>
            {filename && tags && (
              <>
                <Typography color="primary" className={styles.filename}>
                  {filename}
                  {tags.map((tag, index) => (
                    <span key={index}>{` #${tag}`}</span>
                  ))}
                </Typography>
              </>
            )}
          </div>
          {!displaySlider && (
            <>
              <div style={{textAlign: 'center'}}>
                <Button variant="contained" color="primary" onClick={handleClickOpenSelectDialog}>
                  Select creative
                </Button>
              </div>
            </>
          )}
          <Dialog open={openSelectDialog} onClose={handleClickCloseSelectDialog}>
            <DialogTitle>Select creative</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                {adList?.map((ad, index) => (
                  <Grid item xs={12} key={index}>
                    <Card sx={{ maxWidth: 345, marginBottom: '30px' }}>
                      <CardActionArea onClick={() => handleClickCard(ad.title)}>
                        <CardMedia
                          component="img"
                          height="140"
                          image={ad.thumbnail}
                          alt={`#${ad.tags.join(' #')}`}
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h5" component="div">
                            {ad.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            #{ad.tags.join(' #')}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    </div>
  );
};

export default GenAiVideoPage;
