import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { getAdContent, getAdList } from './adTechApi';
import { AdContentItem, AdItem } from './adTechTypes';

export const useAdList = (options: UseQueryOptions<AdItem[]> = {}) =>
  useQuery<AdItem[], unknown>({
    queryKey: ['adList'],
    queryFn: getAdList,
    ...options
  });

export const useAdContent = (options: UseQueryOptions<AdContentItem[]> = {}) =>
  useQuery<AdContentItem[], unknown>({
    queryKey: ['adContent'],
    queryFn: getAdContent,
    ...options
  });